<template>
    <div class="menu commons_box">
        <RouterLink v-if="includePublishable('inscription')" class="item_menu text_dark" to="/calendrier">
            <span class="libelle_item_menu">Calendrier des inscriptions</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink v-if="includePublishable('choix_poste')" class="item_menu text_dark" to="/choixposte">
            <span class="libelle_item_menu">Choix des vœux</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink
            v-if="includePublishable('convocations_admissibilite')"
            class="item_menu text_dark"
            to="/convocations/admissibilite"
        >
            <span class="libelle_item_menu">Convocations aux épreuves écrites</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink
            v-if="includePublishable('convocations_admission')"
            class="item_menu text_dark"
            to="/convocations/admission"
        >
            <span class="libelle_item_menu">Convocations pour l'oral - Planning de passage</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink
            v-if="includePublishable('demissions_admission')"
            class="item_menu text_dark"
            to="/demissions/concours"
        >
            <span class="libelle_item_menu">Formulaire de démission de l'oral</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink
            v-if="includePublishable('demissions_admission')"
            class="item_menu text_dark"
            to="/demissions/epreuve"
        >
            <span class="libelle_item_menu">Démission d'une épreuve de langue facultative</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink
            v-if="includePublishable('resultats_admissibilite')"
            class="item_menu text_dark"
            to="/resultats/admissibilite"
        >
            <span class="libelle_item_menu">Résultats d'admissibilité</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink
            v-if="includePublishable('resultats_admission')"
            class="item_menu text_dark"
            to="/resultats/admission"
        >
            <span class="libelle_item_menu">Résultats d'admission</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink
            v-if="includePublishable('reclamations_ecrits')"
            class="item_menu text_dark"
            to="/reclamations/admissibilite"
        >
            <span class="libelle_item_menu">Réclamations de l'écrit</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink
            v-if="includePublishable('reclamations_oraux')"
            class="item_menu text_dark"
            to="/reclamations/admission"
        >
            <span class="libelle_item_menu">Réclamations de l'oral</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink
            v-if="includePublishable('reclamations_postconcours')"
            class="item_menu text_dark"
            to="/reclamations/postconcours"
        >
            <span class="libelle_item_menu">Réclamations post concours</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink v-if="includePublishable('copie')" class="item_menu text_dark" to="/copies">
            <span class="libelle_item_menu">Consultation des copies</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink
            v-if="includePublishable('bourse_emploi_postes') || includePublishable('bourse_emploi_profil')"
            class="item_menu text_dark"
            to="/bourse_emploi"
        >
            <span class="libelle_item_menu">Bourse à l'emploi</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink class="item_menu text_dark" to="/compte">
            <span class="libelle_item_menu">Paramètres du compte</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <RouterLink class="item_menu text_dark" to="/assistance">
            <span class="libelle_item_menu">Support technique</span>
            <FontAwesomeIcon class="text_primary fa-lg item_right" icon="angle-right" />
        </RouterLink>
        <div class="item_menu no_border_bottom text_dark" @click="logout">
            <span class="libelle_item_menu">Déconnexion</span>
            <FontAwesomeIcon class="text_tertiary fa-lg item_right" icon="sign-out" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'NavMenu',
    data() {
        return {
            user: this.$store.getters['user/user_select']
        }
    },
    methods: {
        /**
         * Vérifie si la valeur est incluse dans la liste publishable_codes
         * @param {string} value - Valeur à vérifier
         * @returns {boolean} - True si la valeur est incluse dans la liste
         */
        includePublishable(value: string): boolean {
            if (this.user?.publishable_codes?.length) {
                return this.user.publishable_codes.includes(value)
            }
            return false
        },

        /**
         * Déconnexion du candidat
         * @return {void}
         */
        logout(): void {
            this.$store.dispatch('auth/logout').then(() => {
                this.$store.commit('user/SET_USER_SELECT', null)
                window.location.href = this.$store.state.auth.back_url
            })
        }
    }
})
</script>

<style lang="scss">
.bg_info {
    background-color: $breadcrumbs !important;
}

.title_bar {
    color: var(--env-color) !important;
}

.commons_content {
    .commons_menu {
        border-bottom: 8px solid var(--env-color) !important;
    }
}

.menu {
    padding: 15px;

    .item_right {
        float: right;
    }

    ul {
        background-color: white;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .item_menu {
        border-bottom: 1px solid grey;
        display: block;
        padding: 20px !important;
        text-align: left !important;
        text-decoration: none;
    }

    .item_menu a,
    .link_action {
        display: block;
        padding: 0 !important;
        text-align: left !important;
        text-decoration: none;
    }

    .item_menu:hover {
        background-color: #f1f1f1;
    }

    .disabled_item:hover {
        background-color: transparent;
    }

    .libelle_item_menu {
        font-size: 14px;
        margin-right: 20px;
    }

    .no_border_bottom {
        border: none;
    }
}
</style>
