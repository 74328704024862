import ChoixPosteService from '../../services/ChoixPosteService'
import { getError } from '../../utils/helpers'

export const namespaced = true

export const state: any = {
    choixPoste: null,
    error_choix_poste: null,
    error_acces_choix_poste: null,
    loading_choix_poste: false
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            choixPoste: null,
            error_choix_poste: null,
            error_acces_choix_poste: null,
            loading_choix_poste: false
        })
    },
    SET_ERROR(state: any, error: any) {
        state.error_choix_poste = error
    },
    SET_ERROR_ACCES(state: any, error: any) {
        state.error_acces_choix_poste = error
    },
    SET_LOADING(state: any, etat: boolean) {
        state.loading_choix_poste = etat
    },
    SET_CHOIX_POSTE(state: any, choixPoste: any) {
        state.choixPoste = choixPoste
    }
}

export const actions = {
    getChoixPostes({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ChoixPosteService.getChoixPoste()
                .then((response: any) => {
                    commit('SET_CHOIX_POSTE', response.data.data.choixPoste)
                    resolve(response)
                })
                .catch((error: any) => {
                    // Dispatch les errors selon si erreur d'accés (403) ou erreur fonctionnelle
                    if (error && error.response.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    postChoixPoste({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ChoixPosteService.postChoixPoste(params)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    // Dispatch les errors selon si erreur d'accés (403) ou erreur fonctionnelle
                    if (error.response.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
                .finally(() => {
                    // commit('SET_LOADING', false)
                })
        })
    },
    choixPostePrioritaire({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ChoixPosteService.choixPostePrioritaire(params)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    // Dispatch les errors selon si erreur d'accés (403) ou erreur fonctionnelle
                    if (error.response.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {}
