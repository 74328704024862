import SupportService from '../../services/SupportService'
import { getError } from '../../utils/helpers'

export const namespaced = true

export const state: any = {
    error_support: null,
    loading_support: false
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            error_support: null,
            loading_support: false
        })
    },
    SET_ERROR(state: any, error: any) {
        state.error_support = error
    },
    SET_LOADING(state: any, etat: boolean) {
        state.loading_support = etat
    }
}

export const actions = {
    sendMessage({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SupportService.sendMessage(payload)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {}
