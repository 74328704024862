import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'bootstrap/dist/css/bootstrap.css'

import { createBootstrap } from 'bootstrap-vue-next'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { type IconPack, library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Loader from './components/Tools/Loader.vue'

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $store: any
        $router: typeof router
        $route: any
    }
}

library.add(fas as IconPack, fal as IconPack)

const app = createApp(App)

app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('LoaderComponent', Loader)

app.use(createBootstrap())
app.use(store)
app.use(router)

router.isReady().then(() => app.mount('#app'))
