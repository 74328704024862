<template>
    <nav class="navbar_top">
        <router-link class="navbar-brand" to="/">
            <img alt="logo" class="logo-s rounded" src="../assets/logo-navbar.svg" />
        </router-link>
        <div v-if="user_select && !error_user">
            <div class="libelle_candidat">
                <p>
                    N° {{ user_select ? user_select.code : '--' }} -
                    {{ user_select ? user_select.name + ' ' + user_select.first_name : '--' }}
                </p>
                <p>{{ user_select ? user_select.email : '--' }}</p>
            </div>
            <div class="icone_menu_web dropdown">
                <button class="dropbtn">
                    <FontAwesomeIcon class="text_secondary fa-2xl" icon="ellipsis-v" />
                </button>
                <NavMenu class="dropdown-content" />
            </div>
            <router-link class="icone_menu" to="/NavMenu">
                <FontAwesomeIcon class="text_secondary fa-2xl" icon="ellipsis-v" />
            </router-link>
        </div>
        <div v-else></div>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import NavMenu from './NavMenu.vue'

export default defineComponent({
    name: 'NavBarTopComponent',
    components: {
        NavMenu
    },
    computed: {
        ...mapState('user', ['user_select', 'error_user'])
    },
    methods: {
        /**
         * Déconnexion du candidat
         * @return {void}
         */
        logout(): void {
            this.$store.dispatch('auth/logout').then(() => {
                this.$store.commit('user/SET_USER_SELECT', null)
                window.location.href = this.$store.state.auth.back_url
            })
        }
    }
})
</script>

<style lang="scss">
.navbar_top {
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 56px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0.6rem;
        min-width: max-content;
    }

    > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .libelle_candidat {
            width: calc(100vw - 190px);
            display: flex;
            flex-flow: column nowrap;
            overflow: hidden;

            > p {
                width: 100%;
                text-align: right;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .icone_menu_web {
            cursor: pointer;
            display: block;
            padding: 0.8rem;

            > button {
                background-color: transparent;
                border: none;
                cursor: pointer;
            }

            .dropdown-content {
                background-color: #f9f9f9;
                border-radius: 5px;
                box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
                display: none;
                max-width: 330px;
                min-width: 160px;
                position: fixed;
                right: 12px;
                top: 12px;
                z-index: 10;
            }

            &.dropdown:hover .dropdown-content {
                display: block;
            }
        }

        .icone_menu {
            cursor: pointer;
            display: none;
            padding: 0.8rem;

            > a {
                color: inherit;
                display: inherit;
                padding: 0;
                text-align: inherit;
                text-decoration: none;
            }
        }
    }

    @media only screen and (max-width: 499px) {
        .icone_menu_web {
            display: none !important;
        }

        .icone_menu {
            display: block !important;
        }
    }
}
</style>
