import UserService from '../../services/UserService'
import { type UserInterface } from '../../types/User'
import { getError } from '../../utils/helpers'

export const namespaced = true

export const state: any = {
    error_user: null,
    loading_user: false,
    user_select: null
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            error_user: null,
            loading_user: false,
            user_select: null
        })
    },
    SET_ERROR(state: any, error: any) {
        state.error_user = error
    },
    SET_LOADING(state: any, etat: boolean) {
        state.loading_user = etat
    },
    SET_USER_SELECT(state: any, user: UserInterface) {
        Object.freeze(user)
        state.user_select = user
    }
}

export const actions = {
    getUser({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        commit('SET_ERROR', null)
        return new Promise((resolve, reject) => {
            UserService.getUser()
                .then((response: any) => {
                    commit('SET_USER_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    putUser({ commit }: { commit: any }, user: UserInterface) {
        return new Promise((resolve, reject) => {
            UserService.putUser(user)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    reject(getError(error))
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    user_select: (state: any) => {
        return state.user_select
    }
}
