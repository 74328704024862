import DemissionService from '../../services/DemissionService'
import { getError } from '../../utils/helpers'

export const namespaced = true

export const state: any = {
    demission_concours: null,
    demission_epreuve: null,
    error_demission: null,
    error_acces_demission: null,
    loading_demission: false
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            demission_concours: null,
            demission_epreuve: null,
            error_demission: null,
            error_acces_demission: null,
            loading_demission: false
        })
    },
    SET_ERROR(state: any, error: any) {
        state.error_demission = error
    },
    SET_ERROR_ACCES(state: any, error: any) {
        state.error_acces_demission = error
    },
    SET_LOADING(state: any, etat: boolean) {
        state.loading_demission = etat
    },
    SET_DEMISSION_CONCOURS(state: any, demission: any) {
        state.demission_concours = demission
    },
    SET_DEMISSION_EPREUVE(state: any, demission: any) {
        state.demission_epreuve = demission
    }
}

export const actions = {
    getDemission({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemissionService.getDemission(params.phase, params.type)
                .then((response: any) => {
                    if (params.type === 'concours') {
                        commit('SET_DEMISSION_CONCOURS', response.data.data)
                    } else {
                        commit('SET_DEMISSION_EPREUVE', response.data.data)
                    }
                    resolve(response)
                })
                .catch((error: any) => {
                    // Dispatch les errors selon si erreur d'accés (403) ou erreur fonctionnelle
                    if (error && error.response.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    postDemission({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemissionService.postDemission(params.phase, params.type, params.puid, params.epreuve_id)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    // Dispatch les errors selon si erreur d'accés (403) ou erreur fonctionnelle
                    if (error.response.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {}
