import ResultatService from '../../services/ResultatService'
import { getError } from '../../utils/helpers'

export const namespaced = true

export const state: any = {
    resultats: [],
    error_resultat: null,
    error_acces_resultat: null,
    loading_resultat: false
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            resultats: [],
            error_resultat: null,
            error_acces_resultat: null,
            loading_resultat: false
        })
    },
    SET_ERROR(state: any, error: any) {
        state.error_resultat = error
    },
    SET_ERROR_ACCES(state: any, error: any) {
        state.error_acces_resultat = error
    },
    SET_LOADING(state: any, etat: boolean) {
        state.loading_resultat = etat
    },
    SET_RESULTATS(state: any, resultats: any) {
        state.resultats = resultats
    }
}

export const actions = {
    getResultats({ commit }: { commit: any }, phase: string) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ResultatService.getResultats(phase)
                .then((response: any) => {
                    commit('SET_RESULTATS', response.data.data)
                    resolve(response)
                })
                .catch((error: any) => {
                    // Dispatch les errors selon si erreur d'accés (403) ou erreur fonctionnelle
                    if (error.response.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getReleveNotePDF({ commit }: { commit: any }, payload: any) {
        return new Promise((resolve, reject) => {
            ResultatService.getReleveNotePDF(payload)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    // Dispatch les errors selon si erreur d'accés (403) ou erreur fonctionnelle
                    if (error.response.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
        })
    }
}

export const getters = {
    resultats: (state: any) => {
        return state.resultats
    },
    error_resultat: (state: any) => {
        return state.error_resultat
    },
    error_acces_resultat: (state: any) => {
        return state.error_acces_resultat
    },
    loading_resultat: (state: any) => {
        return state.loading_resultat
    }
}
