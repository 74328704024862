import * as API from './API'

export default {
    getFormulaires() {
        return API.apiClient.get(`/formulaires`)
    },
    postFormulaires(formulaire_id: number, payload: any) {
        return API.apiClient.post(`/formulaires/${formulaire_id}`, payload)
    },
    getFormulaire(formulaire_id: number) {
        return API.apiClient.get(`/formulaires/${formulaire_id}`)
    },
    getDocument(formulaire_id: number, document_uuid: string) {
        return API.apiClient.get(`/formulaires/${formulaire_id}/documents/${document_uuid}?format=base64`)
    },
    postDocument(formulaire_id: number, payload: any) {
        return API.apiClient.post(`/formulaires/${formulaire_id}/documents`, payload)
    },
    deleteDocument(formulaire_id: number, document_uuid: string) {
        return API.apiClient.delete(`/formulaires/${formulaire_id}/documents/${document_uuid}`)
    },
    getDocumentReference(formulaire_id: number, document_uuid: string) {
        return API.apiClient.get(`/publication/${formulaire_id}/documents/${document_uuid}?format=b64`)
    }
}
