import { CandidacyStatus } from '../types/BourseEmploi'
import * as API from './API'

export default {
    async getBourseEmploi(): Promise<any> {
        return await API.apiClient.get(`/job-market`)
    },
    async getJobDocument(publicationDataId: number, mediaUuid: string): Promise<any> {
        return await API.apiClient.get(`/candidacy/${publicationDataId}/media/${mediaUuid}?format=b64`)
    },
    async updateCandidacy(puid: number, payload: { id: number; status: CandidacyStatus }): Promise<any> {
        return await API.apiClient.post(`/candidacy/puid/${puid}`, { candidacies: payload })
    },
    async addCandidacy(puid: number, jobId: number): Promise<any> {
        return await API.apiClient.post(`/candidacy/puid/${puid}/add_candidacy`, { id: jobId })
    },
    async removeCandidacy(puid: number, jobId: number): Promise<any> {
        return await API.apiClient.delete(`/candidacy/puid/${puid}/remove_candidacy`, { data: { id: jobId } })
    },
    getDocument(publicationDataId: number, document_uuid: string) {
        return API.apiClient.get(`/job-market/${publicationDataId}/documents/${document_uuid}?format=base64`)
    }
}
