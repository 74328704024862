import * as API from './API'

export default {
    getDemission(phase: string, type: string) {
        return API.apiClient.get(`/demissions/${phase}/${type}`)
    },
    postDemission(phase: string, type: string, puid: number, epreuve_id?: number) {
        if (epreuve_id) {
            return API.apiClient.post(`/demissions/${phase}/${type}/puid/${puid}/${epreuve_id}`)
        } else {
            return API.apiClient.post(`/demissions/${phase}/${type}/puid/${puid}`)
        }
    }
}
