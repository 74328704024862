import { createStore } from 'vuex'

import * as auth from './modules/Auth'
import * as bourseEmploi from './modules/BourseEmploi'
import * as choixPoste from './modules/ChoixPoste'
import * as convocation from './modules/Convocation'
import * as copie from './modules/Copie'
import * as demission from './modules/Demission'
import * as formulaire from './modules/Formulaire'
import * as parameter from './modules/Parameter'
import * as reclamation from './modules/Reclamation'
import * as resultat from './modules/Resultat'
import * as support from './modules/Support'
import * as user from './modules/User'

const store = createStore({
    strict: false,
    actions: {
        reset(context: any) {
            context.commit('auth/RESET_STATE')
            context.commit('user/RESET_STATE')
            context.commit('reclamation/RESET_STATE')
            context.commit('resultat/RESET_STATE')
            context.commit('convocation/RESET_STATE')
            context.commit('demission/RESET_STATE')
            context.commit('support/RESET_STATE')
            context.commit('choixPoste/RESET_STATE')
            context.commit('formulaire/RESET_STATE')
            context.commit('parameter/RESET_STATE')
            context.commit('copie/RESET_STATE')
            context.commit('bourseEmploi/RESET_STATE')
        }
    },
    modules: {
        auth,
        user,
        reclamation,
        resultat,
        convocation,
        demission,
        support,
        choixPoste,
        formulaire,
        parameter,
        copie,
        bourseEmploi
    }
})

export default store
