<template>
    <div :class="`commons_content absolute0 ${showMenu ? '' : 'no-menu'}`">
        <div v-if="showMenu" class="commons_menu">
            <!-- Barre de navigation -->
            <NavBarTop />
        </div>
        <div v-if="loading_user" class="commons_inner margin_top_loading">
            <LoaderComponent />
        </div>
        <main v-else class="commons_inner">
            <!-- Page content -->
            <RouterView />

            <!-- Assistance -->
            <RouterLink v-if="!showMenu" class="sticky link_support" to="/assistance">
                <FontAwesomeIcon class="text_primary icon_support" icon="map-marker-question" />
                <div class="libelle_support text_primary">Support technique</div>
            </RouterLink>
        </main>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NavBarTop from './components/NavBarTop.vue'

import { mapState } from 'vuex'

export default defineComponent({
    name: 'App',
    components: {
        NavBarTop
    },
    computed: {
        ...mapState('user', ['user_select', 'loading_user', 'error_user']),
        ...mapState('reclamation', ['reclamation']),

        showMenu(): boolean {
            const routes = [
                'login',
                'change-password',
                'reset-password',
                'forgot-password',
                'register',
                'conditions-utilisation',
                'verify-email'
            ]
            return !routes.includes(this.$store.state.auth.to_url)
        }
    },
    created() {
        document.documentElement.style.setProperty('--env-color', import.meta.env.VITE_COLOR)

        const noLoginPages = [
            'identityservererror',
            'unknownuser',
            'login',
            'reset-password',
            'forgot-password',
            'change-password',
            'register',
            'conditions-utilisation',
            'assistance'
        ]

        if (!noLoginPages.includes(this.$store.state.auth.to_url) && this.$route.path !== '/version') {
            this.$store.dispatch('user/getUser').catch(() => console.log({ e: this.$store.state.user.error_user }))
        }
    }
})
</script>

<style lang="scss">
:root {
    --env-color: #000000;
}

.btn-env-color {
    background-color: var(--env-color) !important;
    border-color: var(--env-color) !important;
    transition: filter 0.15s ease-in-out;

    &:hover {
        filter: brightness(90%);
    }
}

.btn-breadcrumbs {
    background-color: $breadcrumbs !important;
    border-color: $breadcrumbs !important;
    color: $dark !important;
    transition: filter 0.15s ease-in-out;

    &:hover {
        filter: brightness(90%);
    }
}

.commons_content {
    background-color: #fcfdff;
}

.commons_inner {
    padding-bottom: 1rem;
}

.margin_top_loading {
    margin-top: 25px;
}

.sticky {
    background-color: $blueWhite;
    bottom: calc(50px - 0.6rem);
    border-radius: 4px;
    position: fixed;
    right: calc(50px - 0.6rem);
    padding: 0.6rem;
    text-align: center;
    z-index: 1;

    &.link_support {
        text-decoration: none;
    }

    .icon_support {
        font-size: 26px;
    }
}

p {
    margin-bottom: unset !important;
}

@media screen and (max-width: 1000px) {
    .sticky {
        background-color: transparent;
        padding: 0.4rem;
        bottom: calc(10px - 0.4rem);
        right: calc(10px - 0.4rem);

        .icon_support {
            font-size: 22px;
        }

        .libelle_support {
            font-family: 'ProductSans-Medium';
            font-size: 13px;
        }
    }
}

@media only screen and (max-width: 500px) {
    body {
        overflow: auto !important;
    }

    .navbar_top {
        position: initial !important;
    }
}
</style>
