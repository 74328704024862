import axios from 'axios'
import * as API from './API'

export const authClient = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    // required to handle the CSRF token
    withCredentials: true,
    withXSRFToken: true,
    xsrfCookieName: 'XSRF-TOKEN-CANDIDATS'
})

export default {
    async sendMessage(payload: any) {
        await authClient.get('/sanctum/csrf-cookie')
        return API.apiClient.post('/assist', payload)
    }
}
