import { type AxiosRequestConfig } from 'axios'
import * as API from './API'

export default {
    getResultats(phase: string) {
        return API.apiClient.get(`/resultats/${phase}`)
    },
    getReleveNotePDF(payload: any) {
        const params: AxiosRequestConfig = {
            headers: {
                Accept: 'application/pdf'
            },
            responseType: 'blob'
        }
        return API.apiClient.get(`/resultats/${payload.phase}/puid/${payload.puid}/pdf`, params)
    }
}
